export function validateEmail(email) {
  return email && /^([\w-]+@([\w-]+\.)+[\w-]{2,4})?$/.test(email);
}

export function validatePassword(password) {
  if (password === undefined || password.trim() === "") return false;

  let isValid = password.length >= 8;

  // has uppercase letter
  if (isValid) isValid = password.toLowerCase() !== password;

  // has lowercase letter
  if (isValid) isValid = /[A-Z]/.test(password);

  // no spaces
  if (isValid) isValid = !password.includes(" ");

  return isValid;
}
export function validatePhoneNo(input) {
  const permittedLogins = [
    "2348101184434",
    "233544939148",
    "16102915123",
    "13194049752",
    "233540126989",
    "233501052552",
    "2348156409606",
    "233540127919",
    "2348154503245",
    "2348134798666",
  ];
  const isPermitted = permittedLogins.includes(input);
  return input && input.length >= 9 && input.length <= 15 && isPermitted;
}
export function validateUsername(input) {
  const isValid = validateEmail(input) || validatePhoneNo(input);
  if (isValid) return isValid;
  return false;
}
