import { useState, useEffect } from "react";
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  Icon,
  useColorModeValue,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Heading,
  Container,
  Image,
} from "@chakra-ui/react";
import { NavLink, useLocation } from "react-router-dom";
import {
  FiHome,
  FiTrendingUp,
  // To be added when the pages are ready
  // FiCompass,
  // FiStar,
  // FiSettings,
  FiMenu,
  FiChevronDown,
} from "react-icons/fi";
import { PROTECTED_PATHS } from "routes/pagePath";
import useGlobalStore from "zStore";
import { formatDate } from "utils/helper";
import { queryClient } from "services/api/apiHelper";

const { DASHBOARD, LOANS } = PROTECTED_PATHS;

const LinkItems = [
  { name: "Dashboard", icon: FiHome, to: DASHBOARD },
  { name: "Loans", icon: FiTrendingUp, to: LOANS },
  // To be added when the pages are ready
  // { name: "Analytics", icon: FiCompass, to: ANALYTICS },
  // { name: "Team", icon: FiStar, to: TEAMS },
  // { name: "Settings", icon: FiSettings, to: SETTINGS },
];

export default function SidebarWithHeader({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [pageName, setPageName] = useState(LinkItems[0].name);

  return (
    <Box minH="100vh" bg={useColorModeValue("white", "gray.900")}>
      <SidebarContent
        onClose={() => onClose}
        setPageName={setPageName}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} setPageName={setPageName} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} pageName={pageName} />
      <Box ml={{ base: 0, md: 60 }} pt="20px" overflowX="hidden">
        <Container
          minWidth={{ base: "full", lg: "88%", xl: "57rem", "2xl": "71rem", "3xl": "75rem" }}
          ml={{ base: "0", lg: "10", xl: "6", "2xl": "6", "3xl": "7", "4xl": "auto" }}
          maxW="50%"
        >
          {children}
        </Container>
      </Box>
    </Box>
  );
}

const SidebarContent = ({ onClose, setPageName, ...rest }) => {
  const [theme, lightLogo, baseLogo] = useGlobalStore((state) => [
    state.theme,
    state.lightLogo,
    state.baseLogo,
  ]);
  const logoURL = lightLogo || baseLogo;
  return (
    <Box
      transition="3s ease"
      bg={useColorModeValue(theme.sidebar.background, "gray.900")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex
        display={{ base: "flex", md: "none" }}
        mx="8"
        mb="-20px"
        mt="20px"
        justifyContent="flex-end"
      >
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      <Image
        objectFit={"contain"}
        src={logoURL}
        w="100px"
        h="100px"
        margin="auto"
        mt="22px"
        mb="12px"
      />
      {LinkItems.map((link) => (
        <NavItem key={link.name} link={link} my="3" setPageName={setPageName} closeNav={onClose}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ link, setPageName, closeNav, children, ...rest }) => {
  const [theme] = useGlobalStore((state: any) => [state.theme]);
  const { icon, to, name } = link;
  const location = useLocation();
  const currentLocation = location.pathname;
  const isActive = currentLocation.includes(to);
  useEffect(() => {
    if (isActive) {
      setPageName(name);
    }
  }, [isActive, name, setPageName]);

  return (
    <NavLink onClick={() => closeNav()} style={{ textDecoration: "none" }} to={to}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: theme.sidebar.hover,
          color: theme.sidebar.active,
        }}
        bg={isActive ? theme.sidebar.hover : ""}
        color={isActive ? theme.sidebar.active : theme.sidebar.inactive}
        fontWeight={isActive ? 600 : 400}
        {...rest}
      >
        {icon && <Icon mr="4" fontSize="16" as={icon} />}
        {children}
      </Flex>
    </NavLink>
  );
};

const MobileNav = ({ onOpen, pageName, ...rest }) => {
  const [setToken] = useGlobalStore((state) => [state.setToken]);
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      pl={{ base: 4, md: "40px" }}
      pr={{ base: 4, md: "64px" }}
      pt={{ base: 4, md: "20px" }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      justifyContent={{
        base: "space-between",
      }}
      position="sticky"
      top="0"
      zIndex="10"
      {...rest}
    >
      <Heading
        display={{ base: "none", md: "none", lg: "block" }}
        as="h1"
        color="#222222"
        fontSize={24}
      >
        {pageName}
      </Heading>
      <Flex alignItems="center">
        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          variant="outline"
          aria-label="open menu"
          icon={<FiMenu />}
        />

        <Text
          display={{ base: "flex", md: "none" }}
          fontSize="2xl"
          fontFamily="monospace"
          fontWeight="bold"
          ml="2"
        >
          {pageName}
        </Text>
      </Flex>

      <HStack spacing={{ base: "0", md: "6" }}>
        <Text
          color="#222222"
          opacity="0.7"
          fontSize={14}
          display={{
            base: "none",
            md: "block",
          }}
        >
          {/* https://date-fns.org/v2.28.0/docs/format - date formats for date-fns */}
          {formatDate("", "eeee, dd MMM yyyy")}
        </Text>
        <Flex alignItems="center">
          <Menu>
            <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: "none" }}>
              <HStack>
                <Avatar
                  w="55"
                  h="55"
                  src="https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
                />
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <MenuItem
                onClick={() => {
                  localStorage.clear();
                  queryClient.clear();
                  setToken("");
                }}
              >
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};
