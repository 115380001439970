import {
  Button,
  VStack,
  Input,
  FormControl,
  FormLabel,
  Flex,
  FormHelperText,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import AuthWrapper from "components/layout/AuthWrapper";
import { __ } from "config";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PUBLIC_PATHS } from "routes/pagePath";
import { authRequest, baseURL } from "services";
import { postBasicRequest } from "services/api/apiHelper";

const ResetPassword = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
  } = useForm();

  const onSuccess = () => {
    localStorage.removeItem("passwordResetToken");
    toast.success("Password changed successfully");
    navigate(PUBLIC_PATHS.LOGIN);
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: postBasicRequest,
    onSuccess,
    onError: (error: AxiosError<Error>) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const submitHandler: SubmitHandler<FieldValues> = (data) => {
    if (data.newPassword !== data.confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }
    mutate({
      url: `${baseURL}${authRequest.UPDATE_PASSWORD}`,
      data: {
        password: data.newPassword,
      },
    });
  };

  return (
    <AuthWrapper name={__("RESET_PASSWORD")}>
      <Flex w="full" justifyContent="center" my="5">
        <form style={{ width: "100%" }} onSubmit={handleSubmit(submitHandler)}>
          <VStack spacing="4" w="full">
            <FormControl>
              <FormLabel>Enter new password</FormLabel>
              <Input
                type="password"
                {...register("newPassword", {
                  required: {
                    value: true,
                    message: "Enter your new password",
                  },
                  minLength: {
                    value: 8,
                    message: "This password is too short",
                  },
                })}
              />
              <FormHelperText fontSize="sm" color="red.400">
                {errors?.newPassword?.message?.toString()}
              </FormHelperText>
            </FormControl>
            <FormControl>
              <FormLabel>Verify password</FormLabel>
              <Input
                type="password"
                {...register("confirmPassword", {
                  required: {
                    value: true,
                    message: "Re-enter your new password",
                  },
                  onChange: (e) => {
                    const prev = getValues("newPassword");
                    if (e.target.value !== prev) {
                      setError("confirmPassword", { message: "passwords do not match" });
                    } else {
                      setError("confirmPassword", { message: "" });
                    }
                  },
                })}
              />
              <FormHelperText fontSize="sm" color="red.400">
                {errors?.confirmPassword?.message?.toString()}
              </FormHelperText>
            </FormControl>
          </VStack>
          <Button isLoading={isLoading} w="full" mt="6" type="submit">
            {__("RESET_PASSWORD")}
          </Button>
        </form>
      </Flex>
    </AuthWrapper>
  );
};

export default ResetPassword;
