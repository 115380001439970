export const PUBLIC_PATHS = {
  LOGIN: "/login",
  FORGOT_PASSWORD: "/password-reset/forgot-password",
  VALIDATE_OTP: "/password-reset/validate-otp",
  PASSWORD_RESET: "/password-reset/reset-password",
};

export const PROTECTED_PATHS = {
  DASHBOARD: "/dashboard",
  LOAN_ANALYTICS: "/loan-analytics",
  LOANS: "/loans",
  LOAN: "/loans/:id",
  OLD_APPLICATION: "/applications/:id",
  APPLICATION_MODEL: "/loans/:id/model-analysis",
  APPLICATIONS: "/applications",
  SETTINGS: "/settings",
  REPAYMENTS: "/repayments",
  REPAYMENT: "/repayments/:id",
  MODEL_CALCULATOR: "/model-calculator",
  ANALYTICS: "/analytics",
  TEAMS: "/teams",
};
