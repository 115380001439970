export const authRequest = {
  GET_CUSTOMIZATION: "/dl/customization",
  GET_LOGO: "/api/dl/lendingCompanyResources?size=1&page=0&name=BASE_LOGO",
  LOGIN: "/dl/authorize",
  RESOURCES: "/api/dl/lendingCompanyResources?page=0&size=20",
  SEND_OTP: "/dl/verification?type=PASSWORD_RESET&email=:email",
  VERIFY_OTP: "/dl/verification",
  UPDATE_PASSWORD: "/api/dl/user/password",
};

export const loanRequest = {
  CREDIT_SCORE: "/api/dl/loanRequests/:referenceId/creditScore",
  FULL_CREDIT_SCORE: "/api/dl/loanRequests/:referenceId/creditScore/fullReport",
  XML_LINK: "/api/dl/crms/:referenceId/download",
  LOAN_REQUEST: "/api/dl/admin/loanRequests",
  GET_LOAN: "/api/dl/admin/loanRequests/:id",
  DISBURSE_LOAN: "/api/dl/admin/loanRequests/:id/disburse",
  CREDIT_RISK: "/api/dl/admin/loanRequests/creditRiskAssessment/:referenceId",
  LENDER_REPAYMENT_INFO: "/api/dl/lendingCompanies/repaymentInfo?currency=:currency",
  UPDATE_EXTRAS: "/api/dl/loanRequests/:referenceId/extras",
  REPAYMENT: "/api/dl/admin/loanRequests/:referenceId/loanDetailSummary",
  REPAYMENT_SCHEDULE: "/api/dl/admin/loanRequests/:id/repaymentSchedules",
  REPAYMENT_INFORMATION: "/api/dl/admin/loanRequests/:id/repaymentInformation",
  DASHBOARD_CARDS: "/api/dl/admin/dashboard/cards",
  ACTIVE_LOANS:
    "/api/dl/admin/dashboard/activeLoans?page=:page&size=:size&createdAfter=:startDate&createdBefore=:endDate",
  OVERDUE_LOANS:
    "/api/dl/admin/dashboard/overdueLoans?page=:page&size=:size&overdueLess=:end&overdueGreater=:start&createdAfter=:startDate&createdBefore=:endDate",
  LOAN_REQUESTS:
    "/api/dl/admin/dashboard/loanRequests?page=:page&size=:size&sort=createdAt,desc&createdAfter=:startDate&createdBefore=:endDate&status=:activeStatus",
  LOAN_REQUESTS_DOWNLOAD:
    "/api/dl/admin/dashboard/loanRequests/download?createdAfter=:startDate&createdBefore=:endDate&sort=createdAt,desc&page=:page&size=:size",
  ACTIVE_LOANS_DOWNLOAD:
    "/api/dl/admin/dashboard/activeLoans/download?createdAfter=:startDate&createdBefore=:endDate&sort=createdAt,desc&page=:page&size=:size",
  OVERDUE_LOANS_DOWNLOAD:
    "/api/dl/admin/dashboard/overdueLoans/download?createdAfter=:startDate&createdBefore=:endDate&sort=createdAt,desc&page=:page&size=:size",
};
