import { Navigate } from "react-router-dom";
import { PROTECTED_PATHS } from "./pagePath";
import { lazy } from "react";
import WithSuspense from "components/HOC/WithSuspense";
import Application from "pages/Application";

const Dashboard = WithSuspense(lazy(() => import("pages/Dashboard")));
const Loans = WithSuspense(lazy(() => import("pages/Loans")));
const Loan = WithSuspense(lazy(() => import("pages/Loans/Loan")));
const ComingSoon = WithSuspense(lazy(() => import("components/ComingSoon")));

const { DASHBOARD, LOANS, OLD_APPLICATION, LOAN, APPLICATION_MODEL, ANALYTICS, TEAMS, SETTINGS } =
  PROTECTED_PATHS;

const PROTECTED_ROUTES = [
  { path: DASHBOARD, element: <Dashboard /> },
  { path: LOANS, element: <Loans /> },
  { path: OLD_APPLICATION, element: <Application /> },
  { path: LOAN, element: <Loan /> },
  { path: APPLICATION_MODEL, element: <Loan isModelAnalysisPage /> },
  { path: DASHBOARD, element: <Dashboard /> },
  { path: ANALYTICS, element: <ComingSoon /> },
  { path: TEAMS, element: <ComingSoon /> },
  { path: SETTINGS, element: <ComingSoon /> },
  { path: "/", element: <Navigate to={DASHBOARD} /> },
  { path: "*", element: <Navigate to="/" /> },
];

export default PROTECTED_ROUTES;
