import React, { useEffect } from "react";
import { Center, Image, Text, Box, Flex } from "@chakra-ui/react";
import useGlobalStore from "zStore";
import { DEFAULT_THEME, THEME_KEY } from "utils/constants";

type AuthWrapperProps = {
  children: React.ReactNode;
  name: string;
};

const AuthWrapper = (props: AuthWrapperProps) => {
  const { children, name } = props;
  const [baseLogo, setIsHome, isHome] = useGlobalStore((state) => [
    state.baseLogo,
    state.setIsHome,
    state.isHome,
  ]);
  useEffect(() => {
    const isHomeValue = localStorage.getItem(THEME_KEY) === DEFAULT_THEME;
    setIsHome(isHomeValue);
  }, []);

  return (
    <Box>
      <Center flexDir="column" maxWidth="400px" w="90%" m="auto" mt="90px">
        <Flex justifyContent="center" flexDir="column" alignItems="center">
          <Image src={baseLogo} w="100%" maxW="150px" />
          {isHome ? (
            <Text my="4" fontSize="20px" fontWeight="700" color="#969696">
              Lending Management
            </Text>
          ) : null}
        </Flex>
        <Text
          fontSize="1.4em"
          fontWeight="900"
          textAlign="left"
          alignSelf="flex-start"
          color="rgb(0, 165, 255)"
          mt="8"
        >
          {name}
        </Text>
        <Box bg="gray.200" h="1px" w="100%"></Box>
        {children}
        {isHome ? null : (
          <Text
            fontSize="14px"
            fontWeight="bold"
            alignSelf="flex-start"
            mt="8"
            ml={{ sm: "0", md: "-4" }}
            display="block"
            color="#303030"
          >
            Powered by OZÉ.
          </Text>
        )}
      </Center>
    </Box>
  );
};

export default AuthWrapper;
