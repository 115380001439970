import { RiCheckFill, RiCloseFill } from "react-icons/ri";

export function CheckIcon() {
  return (
    <div className="d-flex justify-content-center mt-auto mb-auto">
      <RiCheckFill className="green-check" />
    </div>
  );
}

export function ErrorIcon() {
  return (
    <div className="d-flex justify-content-center mt-auto mb-auto">
      <RiCloseFill className="error-check" />
    </div>
  );
}
