import React from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import PUBLIC_ROUTES from "routes/publicRoutes";

const AppWrapper = () => {
  const routes = useRoutes(PUBLIC_ROUTES);
  return routes;
};

function UnAuthenticated() {
  return (
    <Router>
      <AppWrapper />
    </Router>
  );
}

export default UnAuthenticated;
