import { Button, Box, HStack, PinInput, PinInputField, Text } from "@chakra-ui/react";
import { AxiosError } from "axios";
import AuthWrapper from "components/layout/AuthWrapper";
import { __ } from "config";
import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PUBLIC_PATHS } from "routes/pagePath";
import { authRequest, baseURL } from "services";
import { putBasicRequest } from "services/api/apiHelper";
import useGlobalStore from "zStore";

const ValidateOtp = () => {
  const navigate = useNavigate();
  const [otpCode, setOtpCode] = useState("");
  const resetEmail = useGlobalStore((state) => state.resetEmail);

  const onSuccess = ({
    data,
  }: {
    data: { accessToken: string };
    message: string;
    status: string;
  }) => {
    localStorage.setItem("passwordResetToken", data.accessToken);
    navigate(PUBLIC_PATHS.PASSWORD_RESET);
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: putBasicRequest,
    onSuccess,
    onError: (error: AxiosError<Error>) => {
      toast.error(error?.response?.data?.message);
      setOtpCode("");
    },
  });

  const handleOtpSubmit = () => {
    if (otpCode.length < 6) {
      toast.error("Your OTP code is invalid");
      return;
    }
    mutate({
      url: `${baseURL}${authRequest.VERIFY_OTP}`,
      data: {
        email: resetEmail,
        code: otpCode,
        type: "PASSWORD_RESET",
      },
    });
  };

  return (
    <AuthWrapper name={__("ENTER_OTP")}>
      <Box my="5">
        <Text mb="2">We&apos;ve sent a 6-digit code to you via email.</Text>
        <Text mb="5">Enter the code here.</Text>
        <HStack spacing="4">
          <PinInput
            placeholder=""
            onChange={(number) => {
              setOtpCode(number);
            }}
            otp
          >
            {[1, 2, 3, 4, 5, 6].map((e) => (
              <PinInputField key={e} py="10" w="14" fontSize="2xl" />
            ))}
          </PinInput>
        </HStack>
      </Box>
      <Button w="70%" isLoading={isLoading} mt="6" onClick={handleOtpSubmit}>
        {__("NEXT")}
      </Button>
    </AuthWrapper>
  );
};

export default ValidateOtp;
