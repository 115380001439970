import Pages from "./pages";
import { ChakraProvider } from "@chakra-ui/react";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import { queryClient } from "services/api/apiHelper";

import theme from "styles/theme";
import "./styles/index.css";
import "react-toastify/dist/ReactToastify.css";

const RenderDevTool = () => {
  if (process.env.NODE_ENV === "development") {
    return <ReactQueryDevtools initialIsOpen={false} />;
  }
  return null;
};

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ToastContainer />
        <Pages />
        <RenderDevTool />
      </QueryClientProvider>
    </ChakraProvider>
  );
};

export default App;
