export const LOANS_REPAYMENT_INFO = "LOANS_REPAYMENT_INFO";
export const CLOSE_LOAN_ACTION = "CLOSE_LOAN_ACTION";
export const DISBURSE_BUTTON = "DISBURSE_BUTTON";
export const CRMS_DISBURSE_BUTTON = "CRMS_DISBURSE_BUTTON";
export const DOWNLOAD_XML_BUTTON = "DOWNLOAD_XML_BUTTON";
export const REPAYMENT_DAYS = "REPAYMENT_DAYS";
export const TAB_VIEW_CRMS_INPUT = "TAB_VIEW_CRMS_INPUT";
export const KYC_AND_CREDIT_RISK_DATA_SUMMARY = "KYC_AND_CREDIT_RISK_DATA_SUMMARY";
export const LOAN_TAB_VIEW_OZE_USAGE = "LOAN_TAB_VIEW_OZE_USAGE";
export const LOAN_TAB_VIEW_REPAYMENT_SCHEDULE = "LOAN_TAB_VIEW_REPAYMENT_SCHEDULE";
export const LOAN_TAB_VIEW_REPAYMENT_INFORMATION = "LOAN_TAB_VIEW_REPAYMENT_INFORMATION";
export const CHECK_MIN_AND_MAX_LOAN_AMOUNT = "CHECK_MIN_AND_MAX_LOAN_AMOUNT";
export const USE_MAX_LOAN_AMOUNT = "USE_MAX_LOAN_AMOUNT"; // IF LOAN AMOUNT IS GREATER THAN THE LC'S MAXIMUM AMOUNT
