export enum LoanStatus {
  APPROVED = "APPROVED",
  ACCEPTED = "ACCEPTED",
  ACTIVE = "ACTIVE",
  DRAFT = "DRAFT",
  COMPLETED = "COMPLETED",
  REJECTED = "REJECTED",
  PENDING = "PENDING",
  MISSING_BANK_ACCOUNT = "MISSING_BANK_ACCOUNT",
  LENDER_ACCEPTED = "LENDER_ACCEPTED",
  DISBURSEMENT_IN_PROGRESS = "DISBURSEMENT_IN_PROGRESS",
  LOAN_APPROVED = "LOAN_APPROVED",
  LOAN_REQUEST_REJECTED = "LOAN_REQUEST_REJECTED",
  AWAITING_SECOND_APPROVAL = "AWAITING_SECOND_APPROVAL",
  BORROWER_SUBMITTED = "BORROWER_SUBMITTED",
  DISBURSED_LOAN = "DISBURSED_LOAN",
  DECLINED = "DECLINED",
}
