import Authenticated from "./Authenticated";
import UnAuthenticated from "./UnAuthenticated";
import { useQueryWrapper } from "services/api/apiHelper";
import { authRequest } from "services";
import { DEFAULT_THEME, EMPTY_IMAGE, QUERY_KEYS, THEME_KEY } from "utils/constants";
import md5 from "blueimp-md5";
import Loader from "components/Loader";
import useGlobalStore, { themes } from "zStore";

const Pages = () => {
  const [setTheme, setBaseLogo, token, setThemeHash, setCurrency, setLoanRanges] = useGlobalStore(
    (state) => [
      state.setTheme,
      state.setBaseLogo,
      state.token,
      state.setThemeHash,
      state.setCurrency,
      state.setLoanRanges,
    ],
  );

  const { isLoading } = useQueryWrapper(
    QUERY_KEYS.CUSTOMIZATION_KEY,
    authRequest.GET_CUSTOMIZATION,
    {
      onSuccess: (data) => {
        const themeAlias = md5(data.alias);
        setThemeHash(themeAlias);
        const currentTheme = themeAlias;
        localStorage.setItem(THEME_KEY, themeAlias);
        setTheme(themes[currentTheme]);
        const baseLogo = data.logoUrl || EMPTY_IMAGE;
        setBaseLogo(baseLogo);
        setCurrency(data.currency || "NGN");
        setLoanRanges(data.loanRanges);
      },

      onError: () => {
        setTheme(themes[DEFAULT_THEME]);
        setBaseLogo(EMPTY_IMAGE);
      },
    },
  );

  const isAuthenticated = token;
  if (isLoading) {
    return <Loader />;
  }

  if (isAuthenticated) {
    return <Authenticated />;
  }
  return <UnAuthenticated />;
};

export default Pages;
