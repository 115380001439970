const en = {
  LOGO: "Logo",
  LENDING_MANAGEMENT: "Lending Management",
  LENDING: "Lending",
  MANAGEMENT: "Management",
  LOGIN: "Login",
  USERNAME: "Username",
  PASSWORD: "Password",
  RESET_PASSWORD: "Reset Password",
  GOOD_MORNING: "Good Morning",
  GOOD_AFTERNOON: "Good Afternoon",
  GOOD_EVENING: "Good Evening",
  PROFILE_PICTURE: "Profile Picture",
  DASHBOARD: "Dashboard",
  LOAN_MANAGEMENT: "Loan Management",
  LOANS: "Loans",
  LOAN_ANALYTICS: "Loan Analytics",
  REPAYMENTS: "Repayments",
  APPLICATIONS: "Applications",
  TOOLS: "Tools",
  MODEL_CALCULATOR: "Model Calculator",
  SETTINGS: "Settings",
  LOANS_OUTSTANDING: "Loans Outstanding",
  NEW_LOAN_APPLICANTS: "New Loan Applicants",
  VIEW: "View",
  DISBURSE: "Disburse",
  REMIND: "Remind",
  CONTACT: "Contact",
  NAME: "Name",
  AMOUNT: "Amount",
  DURATION: "Duration",
  STATUS: "Status",
  QUICK_ACTIONS: "Quick Actions",
  SEE_ALL_NEW_APPLICANTS: "See All New Applicants",
  SEE_ALL_APPLICANTS: "See All Applicants",
  EXISTING_LOAN_APPLICATION: "Existing Loan Applications",
  PENDING: "Pending",
  ACCEPTED: "Accepted",
  REJECTED: "Rejected",
  TOTAL_LOAN_SUMMARY: "Total Loan Summary",
  TOTAL_INTEREST_EARNED: "Total Interest Earned",
  TOTAL_AMOUNT_DISBURSED: "Total Amount Disbursed",
  TOTAL_VALUE_OF_ACTIVE_LOANS: "Total Value of Active Loans",
  VIEW_FULL_BREAKDOWN: "View Full Breakdown",
  NEW: "New",
  ACTIVE_LOANS_BY_DAY: "Active Loans By Day",
  ENTER_PASSWORD: "Enter Password",
  ENTER_NEW_PASSWORD: "Enter New Password",
  CONFIRM_NEW_PASSWORD: "Confirm New Password",
  PROFILE: "Profile",
  EMAIL: "Email",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  PHONE_NUMBER: "Phone Number",
  UPDATE: "Update",
  CHANGE_PICTURE: "Change Picture",
  SEARCH: "Search",
  BUSINESS_NAME: "Business Name",
  USER_DETAILS: "User Details",
  ACTIONS: "Actions",
  RUN_MODEL: "Run Model",
  RUN_MODEL_WITH_CUSTOM_THRESHOLD: "Run Model With Custom Threshold",
  REFRESH_ALL_DATA: "Refresh All Data",
  VIEWING_: "Viewing ",
  _LOAN_APPLICATION: "'s Loan Application",
  CUSTOMER_PHONE_NUMBER: "Customer Phone Number",
  CUSTOMER_ID: "Customer ID",
  LOAN_APPLICATION: "Loan Application",
  OZE_USAGE: "OZÉ Usage",
  APPLICATION_DEMOGRAPHICS: "Application Demographics",
  BANKING_HISTORY: "Banking History",
  UPLOADED_DOCUMENTS: "Uploaded Documents",
  REFRESH_DATA: "Refresh Data",
  FULL_NAME: "Full Name",
  BUSINESS_TIN_NUMBER: "Business TIN Number",
  USER_S_TRADE_ASSOCIATION: "User's Trade Association",
  YEAR_BUSINESS_STARTED: "Year Business Started",
  LOAN_S_REQUEST_VALUE: "Loan's Request Value",
  LOAN_S_CRMS_VALUE: "CRMS NO",
  REASON_S_FOR_LOAN: "Reason(s) For Loan",
  EMAIL_ADDRESS: "Email Address",
  BUSINESS_REGION: "Business Region",
  BUSINESS_ADDRESS: "Business Address",
  GHANA_POST_ADDRESS: "Ghana Post Address",
  MOBILE_MONEY_BALANCE: "Mobile Money Balance",
  USER_LAST_LOGIN: "User Last Login",
  REPORTED_EXPENSES: "Reported Expenses (last 90 days)",
  REPORTED_PAYABLES: "Reported Payables",
  NUMBER_OF_TRANSACTIONS: "Number of Transactions (all time)",
  NOTIFICATION_READ: "Notification Read",
  TRANSACTIONS_PER_MONTH: "Transactions Per Month",
  AVERAGED_OVER_THE_LAST_MONTH: "Averaged over the last month",
  AVERAGED_OVER_THE_3_MONTH: "Averaged over the last 3 months",
  CURRENT_CASH_BALANCE: "Current Cash Balance",
  INITIAL_CASH_BALANCE: "Initial Cash Balance",
  REPORTED_SALES: "Reported Sales (last 90 days)",
  NUMBER_OF_SALES_MADE: "Number of Sales Made",
  MOM_GROWTH_RATE: "MoM Growth Rate",
  NUMBER_OF_RECEIPT_SENT: "Number of Receipt Sent",
  TOTAL_PROFITS: "Total Profits",
  TRAILING_AVERAGE_OVER_3_MONTH: "Trailing Average over 3 Month",
  NO_DOCUMENT_AVAILABLE: "No Documents Available",
  FILES: "Files",
  RECEIVE_MAIL_NOTIFICATIONS: "Receive email notifications",
  FORGOT_PASSWORD: "Forgot Password?",
  SIGN_IN: "Sign In",
  BACK_TO_LOGIN: "Back to Login",
  SHOW: "Show",
  HIDE: "Hide",
  INVALID_USERNAME_OR_PASSWORD: "Invalid Username or Password",
  PROCESSING: "Processing...",
  WELCOME: "Welcome",
  EXPORT_CSV: "Export (CSV)",
  EXPORTING: "Exporting...",
  TRANSACTION_PER_MONTH: "Transactions per month",
  PAGE_NOT_AVAILABLE: "Page currently not available",
  UNABLE_TO_FETCH_LOAN_APPLICATIONS: "Unable to fetch loan applications",
  ERROR: "Error",
  NO_DATA_AVAILABLE: "NO DATA AVAILABLE",
  PLEASE_LOGIN: "Please login",
  APPROVED: "Approved",
  HOUSING_SITUATION: "Housing Situation",
  PERSONAL_INFORMATION: "Personal Information",
  BUSINESS_INFORMATION: "Business Information",
  FINANCIAL_INFORMATION: "Financial Information",
  LOAN_INFORMATION: "Loan Information",
  REPAYMENT_INFORMATION: "Repayment Info",
  DATE_OF_BIRTH: "Date of Birth",
  MARITAL_STATUS: "Marital Status",
  GENDER: "Gender",
  NUMBER_OF_CHILDREN: "Number of Children",
  NUMBER_OF_CELL_PHONES: "Number of Cell Phones",
  PERIOD_USING_CELL_PHONES: "Period using cell phones",
  EDUCATION: "Education",
  OWNS_A_CAR: "Owns a Car",
  CAR_PRODUCTION_YEAR: "Car Production Year",
  YES: "Yes",
  NO: "No",
  WHATSAPP_TYPE: "WhatsApp Type",
  NUMBER_OF_FAMILY_SUPPORT: "Number of Family Support",
  EMERGENCY_CONTACT_PHONE_NO: "Emergency Contact Phone No",
  BUSINESS_DESCRIPTION: "Business Description",
  NUM_OF_EMPLOYEES: "Number of Employees",
  BUSINESS_TIN_TYPE: "Business Tin Type",
  LAST_PERIOD_WIHOUT_INCOME: "Last period without income",
  CONFIRM_RECORD_TRX_WITH_OZE: "Confirm record transactions with OZÉ",
  CURRENT_BANK_BALANCE: "Current Bank Balance",
  HAVE_OTHER_BUSINESS: "Have Other Financial Source",
  CONFIRM_ENTERED_ACCURATE_INFORMATION: "Confirm User Entered Accurate Information",
  INCOME_FROM_OTHER_SOURCE: "Income from other source",
  APPLICATION_ID: "Application ID",
  COMPANY_NAME: "Company Name",
  AGE: "Age",
  EDUCATION_LEVEL: "Education Level",
  REGION: "Region",
  LOAN_DATE_REQUESTED: "Loan Date Requested",
  AMOUNT_REQUESTED: "Amount Requested",
  LOAN_USE: "Loan Use",
  LOG_OUT: "Log Out",
  ACCEPT: "Accept",
  REJECT: "Reject",
  COMING_SOON: "Coming Soon",
  COMMENTS: "Comments",
  REQUIRED: "Required",
  UPDATE_LOAN_REQUEST: "Update Loan Request",
  ARE_YOU_SURE_YOU_WANT_TO_UPDATE_LOAN_REQ_STATUS:
    "Are you sure you want to update the status of this loan request?",
  PLEASE_PROVIDE_A_FEEDBACK_FOR_THE_LOAN_APPLICATION:
    "Please provide a feedback for the loan application",
  ERROR_UPDATING_LOAN_APPLICATION: "Error updating loan application",
  LOAN_APPLICATION_UPDATE_SUCCESSFUL: "Loan application update successful",
  NO_COMMENTS: "No Comments",
  CANCEL: "Cancel",
  REQUEST_REVIEW: "Request Review",
  MODEL_PROGRESS: "Model Progress",
  COMPLETE: "Complete",
  VIEWING_MODEL_RESULTS: "Viewing Model Results",
  MODEL_RESULTS: "Model Results",
  S_DATA: "'s Data",
  S_RATING: "'s rating",
  RETURN_TO_: "Return to",
  SEND_LOAN_OFFER: "Send Loan Offer",
  SEND_OFFER: "Send Offer",
  KYC_AND_CREDIT_RISK_DATA_SUMMARY: "KYC & Credit Risk Data Summary",
  REQUIRE_REVIEW: "Requires Review",
  AMOUNT_REPAID: "Amount Repaid",
  AMOUNT_OUTSTANDING: "Amount Outstanding",
  PAYMENTS_COLLECTED: "Payments Collected",
  AVG_DAYS_LATE: "Avg Days Late",
  LOAN_APPROVED: "Loan Approved",
  LENDER_ACCEPTED: "Accepted",
  DRAFT: "In Draft",
  LOAN_REQUEST_REJECTED: "Rejected (Unqualified)",
  REQUIRE_BORROWER_REVIEW: "Requires borrower review",
  BORROWER_SUBMITTED: "Pending",
  LOAN_OFFER: "Sent Loan Offer",
  BORROWER_ACCEPTED: "Borrower Accepted",
  BORROWER_DECLINED: "Borrower Rejected",
  SEND_REJECTION: "Send Rejection",
  ERROR_DISBURSING_LOAN_APPLICATION: "Error disbursing loan",
  LOAN_DISBURSEMENT_REQUEST_SUCCESSFUL: "Loan disbursement request successful",
  DISBURSED_LOAN: "Loan Disbursed",
  DISBURSEMENT_IN_PROGRESS: "Disbursement In Progress",
  DISBURSEMENT_FAILED: "Disbursement Failed",
  MISSING_BANK_ACCOUNT: "No bank account provided",
  ACTIVE: "Active",
  COMPLETED: "Completed",
  AMOUNT_FINANCED: "Amount Financed",
  INTEREST_RATE: "Interest Rate",
  TOTAL_PAID: "Total Amount Paid ",
  NEXT_AMOUNT: "Next Amount",
  NEXT_PAYMENT: "Next Payment Date",
  MONTHLY: "month(s)",
  DAILY: "days",
  REPAYMENT_SCHEDULE: "Repayment Schedule",
  AWAITING_SECOND_APPROVAL: "Awaiting Second Approval",
  CONFIRM: "Confirm",
  ENTER_OTP: "Enter OTP",
  NEXT: "Next",
};

export default en;
