import { GLOBAL_STORE } from "utils/constants";
import create from "zustand";
import { persist } from "zustand/middleware";

export const themes = {
  "4fe1fdabe0194933c66156c5c56a10fe": {
    brand: {
      primary: "#1246ea",
      secondary: "#7a7a7a",
      success: "#00dca4",
      danger: "#fa6b64",
      warning: "#ffce51",
      info: "#161a5c",
      black: "#222222",
      sidebar: "#f8f8f8",
      green: "#00dca4",
      red: "#fa6b64",
      yellow: "#f4d0ec",
      white: "#ffffff",
    },
    sidebar: {
      background: "#f8f8f8",
      active: "#161A5C",
      inactive: "#22222270",
      hover: "#161A5C10",
    },
    pendingCard: {
      background: "#1246EA",
      color: "#1246EA",
      chartBg: "#f4f4f7",
      chartColor: "#6F76E1",
    },
    button: {
      primary: {
        background: "#1246ea05",
        color: "#1246ea",
      },
      outline: {
        background: "transparent",
        color: "#161a5c",
      },
    },
    chart: {
      schedule: "#00dca4",
      atRisk: "#f4d0ec",
      overdue: "#fa6b64",
      performance: "#1246ea",
    },
    logo: "#000",
  },
  "7a3e9b55d898410104f966e99f2cc16a": {
    brand: {
      primary: "#005b82",
      secondary: "#7a7a7a",
      success: "#00b107",
      danger: "#dd352e",
      warning: "#005b82",
      info: "linear-gradient(0deg, #023448 2.33%, #015D86 100%);",
      black: "#222222",
      green: "#bed600",
      red: "#dd352e",
      yellow: "#e5a004",
      white: "#ffffff",
    },
    sidebar: {
      background: "linear-gradient(0deg, #023448 2.33%, #015D86 100%)",
      active: "#fff",
      inactive: "#fff",
      hover: "#FFFFFF20",
    },
    pendingCard: {
      background: "#BED600",
      color: "#4A4A4A",
      chartBg: "#fcfdf2",
      chartColor: "#bed600",
    },
    button: {
      primary: {
        background: "#bed600",
        color: "#4a4a4a",
      },
      outline: {
        background: "transparent",
        color: "#005b82",
      },
    },
    chart: {
      schedule: "#bed600",
      atRisk: "#e5a004",
      overdue: "#dd352e",
      performance: "#005b82",
    },
    logo: "",
  },
  "0a0d53a0569b8f50c07f3cc059cc661e": {
    brand: {
      primary: "#005f2a",
      secondary: "7a7a7a",
      success: "#00b107",
      danger: "#fa6b64",
      warning: "#e5a004",
      info: "#005f2a",
      black: "#222222",
      sidebar: "#f8f8f8",
      green: "#daeacd",
      red: "#fa6b64",
      yellow: "#e5a004",
      white: "#ffffff",
    },
    pendingCard: {
      background: "#005F2A",
      color: "#005F2A",
      chartBg: "#fefaf3",
      chartColor: "#e5a004",
    },
    button: {
      primary: {
        background: "#005f2a0d",
        color: "#005f2a",
      },
      outline: {
        background: "transparent",
        color: "#005f2a",
      },
    },
    chart: {
      schedule: "#daeacd",
      atRisk: "#e5a004",
      overdue: "#fa6b64",
      performance: "#e5a004",
    },
    sidebar: {
      background: "#f8f8f8",
      active: "#161A5C",
      inactive: "#22222270",
      hover: "#161A5C10",
    },
    logo: "",
  },
  "6b7649fb5ab08246ae2870aaa06e6008": {
    brand: {
      primary: "#005b82",
      secondary: "#7a7a7a",
      success: "#00b107",
      danger: "#dd352e",
      warning: "#005b82",
      info: "linear-gradient(0deg, #023448 2.33%, #015D86 100%);",
      black: "#222222",
      green: "#bed600",
      red: "#dd352e",
      yellow: "#e5a004",
      white: "#ffffff",
    },
    sidebar: {
      background: "linear-gradient(0deg, #023448 2.33%, #015D86 100%)",
      active: "#fff",
      inactive: "#fff",
      hover: "#FFFFFF20",
    },
    pendingCard: {
      background: "#BED600",
      color: "#4A4A4A",
      chartBg: "#fcfdf2",
      chartColor: "#bed600",
    },
    button: {
      primary: {
        background: "#bed600",
        color: "#4a4a4a",
      },
      outline: {
        background: "transparent",
        color: "#005b82",
      },
    },
    chart: {
      schedule: "#bed600",
      atRisk: "#e5a004",
      overdue: "#dd352e",
      performance: "#005b82",
    },
    logo: "",
  },
};

type LoanRangesType = {
  id: number;
  minimum: number;
  maximum: number;
  currency: string;
  enabled: boolean;
  createdAt: number;
  updatedAt: number;
};

interface GlobalStoreState {
  theme: Record<string, any>;
  setTheme: (theme: Record<string, any>) => void;
  logo: string;
  setLogo: (logo: string) => void;
  baseLogo: string;
  setBaseLogo: (logo: string) => void;
  lightLogo: string;
  setLightLogo: (logo: string) => void;
  darkLogo: string;
  setDarkLogo: (logo: string) => void;
  token: string;
  setToken: (token: string) => void;
  isHome: boolean;
  setIsHome: (isHome: boolean) => void;
  themeHash: string;
  setThemeHash: (themeHash: string) => void;
  currency: string;
  setCurrency: (currency: string) => void;
  loanRanges: LoanRangesType[];
  setLoanRanges: (loanRanges: any[]) => void;
  resetEmail: string;
  setResetEmail: (resetEmail: string) => void;
}

const globalStore = (set) => ({
  theme: null,
  setTheme: (theme) => {
    set({ theme });
  },
  logo: "",
  setLogo: (logo) => set({ logo }),
  baseLogo: "",
  setBaseLogo: (baseLogo) => set({ baseLogo }),
  lightLogo: "",
  setLightLogo: (lightLogo) => set({ lightLogo }),
  darkLogo: "",
  setDarkLogo: (darkLogo) => set({ darkLogo }),
  token: "",
  setToken: (token) => set({ token }),
  isHome: false,
  setIsHome: (isHome) => set({ isHome }),
  themeHash: "",
  setThemeHash: (themeHash) => set({ themeHash }),
  currency: "NGN",
  setCurrency: (currency) => set({ currency }),
  loanRanges: [],
  setLoanRanges: (loanRanges: LoanRangesType[]) => set({ loanRanges }),
  resetEmail: "",
  setResetEmail: (resetEmail: string) => set({ resetEmail }),
});

const persistedGlobalStore: any = persist(globalStore, { name: GLOBAL_STORE });

const useGlobalStore = create<GlobalStoreState>(persistedGlobalStore);

export type GlobalStore = ReturnType<typeof globalStore>;

export default useGlobalStore;
