import { format } from "date-fns";
import { GLOBAL_STORE } from "./constants";
import { LoanStatus } from "./status";
import configs from "./configRules";
import * as rules from "./rules";

export const hasToken = (tokens: any) => {
  return tokens && tokens.accessToken !== null && tokens.accessToken !== undefined;
};

export const getToken = (): string => {
  const store = JSON.parse(localStorage.getItem(GLOBAL_STORE) || "");
  const token = store.state.token;
  return token;
};

export const convertParamsToString = (
  url: string,
  urlVariables: Record<string, string>,
): string => {
  let finalURL = url;

  for (const [key, value] of Object.entries(urlVariables)) {
    finalURL = finalURL.replace(`:${key}`, value);
  }

  return finalURL;
};

export const formatCurrency = (value: number, currency = "NGN"): string => {
  if (typeof currency !== "string") return "";
  return new Intl.NumberFormat("en-NG", {
    style: "currency",
    minimumFractionDigits: 2,
    currency,
    // currencyDisplay: "narrowSymbol",
    currencyDisplay: "code",
  }).format(value || 0);
};

/* https://date-fns.org/v2.28.0/docs/format - date formats for date-fns */
export function formatDate(date: string, dateFormat = "dd-MMM-yyyy") {
  if (typeof date !== "string") return "";
  const formattedDate = format(new Date(date || new Date()), dateFormat);
  return formattedDate;
}

export const snakeCaseToTitle = (snakeText: string) => {
  snakeText = snakeText[0].toUpperCase() + snakeText.slice(1);
  snakeText = snakeText.replace(/_(.)/g, (g) => g.toUpperCase());
  return snakeText.replace(/_/g, " ");
};

const hasRule = (theme: string, rule: string) => {
  if (!theme) {
    window.location.pathname === "/";
  }
  if (configs && configs[theme]) {
    return !configs[theme].has(rule);
  }
  return false;
};

export const showSendLoanRequestButton = (status: string, creditRisk: any, loanRanges?: any) => {
  if (!creditRisk) return false;

  const theme = localStorage.getItem("theme") || "";

  const checkMinAndMax = hasRule(theme, rules.CHECK_MIN_AND_MAX_LOAN_AMOUNT);

  let result = false;

  result = (status &&
    (status === LoanStatus.DRAFT ||
      status === LoanStatus.BORROWER_SUBMITTED ||
      status === LoanStatus.PENDING ||
      status === LoanStatus.AWAITING_SECOND_APPROVAL)) as boolean;
  if (checkMinAndMax && loanRanges.length && loanRanges[0]?.minimum && loanRanges[0]?.maximum) {
    result = result && !(creditRisk?.maxAmount < loanRanges[0]?.minimum);
  }
  return result;
};
