import { FormControl, Input, Button, FormHelperText, Flex } from "@chakra-ui/react";
import axios, { AxiosError } from "axios";
import AuthWrapper from "components/layout/AuthWrapper";
import { __ } from "config";
import { useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PUBLIC_PATHS } from "routes/pagePath";
import { authRequest, baseURL } from "services";
import { convertParamsToString } from "utils/helper";
import useGlobalStore from "zStore";

const ForgotPassword = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const setResetEmail = useGlobalStore((state) => state.setResetEmail);

  const onSuccess = () => {
    setResetEmail(email);
    navigate(PUBLIC_PATHS.VALIDATE_OTP);
  };

  const { isFetching } = useQuery({
    queryKey: ["GET_OTP"],
    queryFn: async () => {
      await axios.get(`${baseURL}${convertParamsToString(authRequest.SEND_OTP, { email })}`, {
        headers: {
          Authorization: process.env.REACT_APP_AUTHORIZATION || "",
        },
      });
    },
    enabled: !!email,
    onSuccess,
    onError: (error: AxiosError<Error>) => {
      toast.error(error?.response?.data?.message);
      setEmail("");
    },
  });

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    setEmail(data.email);
  };

  return (
    <AuthWrapper name={__("FORGOT_PASSWORD")}>
      <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDir="column" alignItems="center">
          <FormControl mt="8">
            <Input
              id="email"
              type="email"
              variant="filled"
              w="full"
              placeholder={__("EMAIL")}
              {...register("email", {
                required: { value: true, message: "Enter your email address" },
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "This email address is invalid",
                },
                onChange: () => {
                  setEmail("");
                },
              })}
            />
            <FormHelperText fontSize="sm" color="red.400">
              {errors?.email?.message?.toString()}
            </FormHelperText>
          </FormControl>
          <Button
            onClick={() => navigate(PUBLIC_PATHS.LOGIN)}
            fontSize="14px"
            variant="link"
            alignSelf="flex-start"
            mt="3"
          >
            {__("BACK_TO_LOGIN")}
          </Button>
          <Button isLoading={isFetching} w="70%" mt="6" type="submit">
            {__("RESET_PASSWORD")}
          </Button>
        </Flex>
      </form>
    </AuthWrapper>
  );
};

export default ForgotPassword;
