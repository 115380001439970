import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PROTECTED_PATHS } from "routes/pagePath";
import { convertParamsToString } from "utils/helper";

const Application = () => {
  const navigate = useNavigate();
  const params = useParams();
  const pagePath = convertParamsToString(PROTECTED_PATHS.LOAN, { id: params.id || "" });

  useEffect(() => {
    navigate(pagePath);
  });
  return null;
};

export default Application;
