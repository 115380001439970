import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import PROTECTED_ROUTES from "routes/protectedRoutes";
import SidebarWithHeader from "components/layout/SideBarNav";
import { authRequest } from "services";
import { useQueryWrapper } from "services/api/apiHelper";
import useGlobalStore from "zStore";

const AppWrapper = () => {
  const routes = useRoutes(PROTECTED_ROUTES);
  return routes;
};

const Authenticated = () => {
  const [setLightLogo] = useGlobalStore((state) => [state.setLightLogo]);
  useQueryWrapper("GET_RESOURCES", authRequest.RESOURCES, {
    onSuccess: (data) => {
      const lightLogo = data.content.find((resource) => resource.name === "LOGO_LIGHT");
      if (lightLogo) {
        setLightLogo(lightLogo.link);
      } else {
        setLightLogo("");
      }
    },
  });

  return (
    <Router>
      <Box>
        <SidebarWithHeader>
          <AppWrapper />
        </SidebarWithHeader>
      </Box>
    </Router>
  );
};

export default Authenticated;
