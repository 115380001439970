import { Navigate } from "react-router-dom";

import Login from "pages/Login";
import ForgotPassword from "pages/ForgotPassword";
import { PUBLIC_PATHS } from "./pagePath";
import ValidateOtp from "pages/ForgotPassword/ValidateOtp";
import ResetPassword from "pages/ForgotPassword/ResetPassword";

const { LOGIN, FORGOT_PASSWORD, PASSWORD_RESET, VALIDATE_OTP } = PUBLIC_PATHS;

const PUBLIC_ROUTES = [
  { path: LOGIN, element: <Login /> },
  { path: FORGOT_PASSWORD, element: <ForgotPassword /> },
  { path: VALIDATE_OTP, element: <ValidateOtp /> },
  { path: PASSWORD_RESET, element: <ResetPassword /> },
  { path: "/", element: <Login /> },
  { path: "*", element: <Navigate to="/" /> },
];

export default PUBLIC_ROUTES;
