import {
  Flex,
  FormControl,
  Input,
  Button,
  InputRightElement,
  useBoolean,
  Box,
  InputGroup,
} from "@chakra-ui/react";
import AuthWrapper from "components/layout/AuthWrapper";
import { CheckIcon, ErrorIcon } from "components/ui/InputCheck";
import { RiEyeFill, RiEyeCloseFill } from "react-icons/ri";
import { PUBLIC_PATHS } from "routes/pagePath";
import { useNavigate } from "react-router-dom";
import { __ } from "config";
import { useForm } from "react-hook-form";
import { validatePassword, validateUsername } from "utils/validation";
import { toast } from "react-toastify";
import { postRequest, useMutationWrapper } from "services/api/apiHelper";
import { authRequest } from "services";
import useGlobalStore from "zStore";
import { LAST_VISITED_URL_KEY } from "utils/constants";
import { useEffect } from "react";

const Login = () => {
  const history = useNavigate();
  const { register, handleSubmit, watch } = useForm();
  const [setToken] = useGlobalStore((state) => [state.setToken]);

  const [showPassword, setShowPassword] = useBoolean();
  const emailValue: string = watch("email") || "";
  const isUserNameValid = validateUsername(emailValue);
  const passwordValue: string = watch("password") || "";
  const isPasswordValid = validatePassword(passwordValue);

  const onSuccess = ({ data }) => {
    setToken(data.accessToken);
    const name = data.user.firstName;
    const lastVisitedUrl = localStorage.getItem(LAST_VISITED_URL_KEY);
    if (lastVisitedUrl) {
      localStorage.removeItem(LAST_VISITED_URL_KEY);
      return history(lastVisitedUrl);
    }
    toast.success(` ${__("WELCOME")} ${name}`);
  };

  const { mutate, isLoading } = useMutationWrapper(postRequest, onSuccess);
  const onSubmit = (formData) => {
    const inputIsNotValid = formData.username.length === 0 || formData.password.length === 0;
    if (inputIsNotValid) return toast.error("Please fill all fields");
    mutate({
      url: authRequest.LOGIN,
      data: formData,
    });
  };

  useEffect(() => {
    const hasTheme = localStorage.getItem("theme");

    if (!hasTheme) {
      history(0);
    }
  }, []);

  return (
    <AuthWrapper name={__("SIGN_IN")}>
      <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
        <FormControl mt="8" w="100%">
          <InputGroup>
            <Input
              id="email"
              type="email"
              variant="filled"
              placeholder={__("USERNAME")}
              {...register("username")}
            />
            {emailValue.length > 0 && (
              <InputRightElement mt="12px">
                {isUserNameValid ? <CheckIcon /> : <ErrorIcon />}
              </InputRightElement>
            )}
          </InputGroup>
        </FormControl>
        <Flex alignItems="center" mt="3" w="100%" justifyContent="flex-end">
          {showPassword ? <RiEyeFill /> : <RiEyeCloseFill />}
          <Button
            onClick={() => setShowPassword.toggle()}
            variant="unstyled"
            fontWeight="normal"
            ml="1"
          >
            {showPassword ? "Hide" : "Show"}
          </Button>
        </Flex>
        <FormControl mt="-8px">
          <InputGroup>
            <Input
              id="pasword"
              type={showPassword ? "text" : "password"}
              variant="filled"
              placeholder={__("PASSWORD")}
              {...register("password")}
            />
            {passwordValue.length > 0 && (
              <InputRightElement mt="12px">
                {isPasswordValid ? <CheckIcon /> : <ErrorIcon />}
              </InputRightElement>
            )}
          </InputGroup>
        </FormControl>
        <Button
          onClick={() => history(PUBLIC_PATHS.FORGOT_PASSWORD)}
          fontSize="14px"
          variant="link"
          alignSelf="flex-start"
          mt="3"
          display="block"
        >
          Forgot Password
        </Button>
        <Box textAlign="center" mt="2rem">
          <Button isLoading={isLoading} w="70%" type="submit">
            Login
          </Button>
        </Box>
      </form>
    </AuthWrapper>
  );
};

export default Login;
