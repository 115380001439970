import { Suspense } from "react";
import Loader from "../Loader";

const WithSuspense = (Component, showLoader = true) =>
  function MainComponent(props) {
    return (
      <Suspense fallback={showLoader ? <Loader /> : "..."}>
        <Component {...props} />
      </Suspense>
    );
  };

export default WithSuspense;
