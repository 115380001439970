import { extendTheme } from "@chakra-ui/react";
import { ButtonStyles as Button } from "./components/buttonStyles";
// custom themes in chakra UI
// https://chakra-ui.com/docs/theming/customize-theme
//https://www.easyreact.com/articles/chakra-ui-customisations

const breakpoints = {
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1280px",
  "2xl": "1440px",
  "3xl": "1512px",
  "4xl": "1536px",
};

const MainTheme = extendTheme({
  fonts: {
    heading: "Lato",
    body: "Lato",
  },
  breakpoints,
  components: {
    Button, // Has to match to the name of the component
  },
});

export default MainTheme;
