import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { LAST_VISITED_URL_KEY } from "utils/constants";
import { getToken } from "utils/helper";
import useGlobalStore from "zStore";
export const baseURL = process.env.REACT_APP_API_ENDPOINT as string;
import version from "utils/app-version.json";
import { queryClient } from "./apiHelper";

export * from "./request";

const userAgent = typeof window !== "undefined" && navigator?.userAgent;
const enviroment = process.env.NODE_ENV;

let appVariant = process.env.REACT_APP_VARIANT;
// disabled this warning so that you can manually change the lending company instead to using the env variable
// eslint-disable-next-line prefer-const
let LMSVariant = 4;
if (enviroment === "development") {
  switch (LMSVariant) {
    case 1:
      appVariant = "test-loans.oze.guru";
      break;
    case 2:
      appVariant = "test-ecobank-loans.oze.guru";
      break;
    case 3:
      appVariant = "test-suntrust-loans.oze.guru";
      break;
    case 4:
      appVariant = "test-ecobank-rwanda-loans.oze.guru";
      break;
    default:
      appVariant = "test-loans.oze.guru";
  }
}

const APP_VARIANT_HEADER = appVariant
  ? {
      "X-Origin": appVariant as string,
    }
  : "";

const axiosInstance: AxiosInstance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json, text/plain, */*",
    "X-Lang": "en",
    "X-User-Agent": `${userAgent} - Oze Web App;Oze Lending Management System: v${version.latest}`,
    "X-Client-Id": process.env.REACT_APP_CLIENT_ID || "",
    "X-Client-Secret": process.env.REACT_APP_CLIENT_SECRET || "",
    ...APP_VARIANT_HEADER,
  },
});

// https://dev.to/charlintosh/setting-up-axios-interceptors-react-js-typescript-12k5
// This adds a token before all the requests.
// https://stackoverflow.com/questions/57251719/acquiring-a-new-token-with-axios-interceptors
const onRequest = (request: AxiosRequestConfig): AxiosRequestConfig => {
  const token = getToken();
  request.headers!.Authorization = "Bearer " + token;
  return request;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  const statusCode = error.response!.status;
  if (statusCode === 401) {
    localStorage.clear();
    queryClient.clear();
    const currentUrl = window.location.pathname;
    localStorage.setItem(LAST_VISITED_URL_KEY, currentUrl);
    useGlobalStore.setState({ token: "" });
  }
  return Promise.reject(error);
};

// https://axios-http.com/docs/interceptors
axiosInstance.interceptors.request.use(onRequest, onRequestError);
axiosInstance.interceptors.response.use(onResponse, onResponseError);

export default axiosInstance;
