import * as rules from "./rules";

/* Pass in the attributes to be hidden/disabled for each lending company below */

const configs = {
  "4fe1fdabe0194933c66156c5c56a10fe": new Set([
    rules.DISBURSE_BUTTON,
    rules.DOWNLOAD_XML_BUTTON,
    rules.CRMS_DISBURSE_BUTTON,
    rules.REPAYMENT_DAYS,
    rules.CHECK_MIN_AND_MAX_LOAN_AMOUNT,
    rules.USE_MAX_LOAN_AMOUNT,
  ]),
  "7a3e9b55d898410104f966e99f2cc16a": new Set([
    rules.CLOSE_LOAN_ACTION,
    rules.CRMS_DISBURSE_BUTTON,
    rules.DOWNLOAD_XML_BUTTON,
    rules.TAB_VIEW_CRMS_INPUT,
    rules.KYC_AND_CREDIT_RISK_DATA_SUMMARY,
    rules.LOAN_TAB_VIEW_OZE_USAGE,
    rules.LOAN_TAB_VIEW_REPAYMENT_INFORMATION,
    rules.CHECK_MIN_AND_MAX_LOAN_AMOUNT,
    rules.USE_MAX_LOAN_AMOUNT,
  ]),
  "0a0d53a0569b8f50c07f3cc059cc661e": new Set([
    rules.LOANS_REPAYMENT_INFO,
    rules.CLOSE_LOAN_ACTION,
    rules.DISBURSE_BUTTON,
    rules.REPAYMENT_DAYS,
    rules.CHECK_MIN_AND_MAX_LOAN_AMOUNT,
    rules.USE_MAX_LOAN_AMOUNT,
  ]),
  "6b7649fb5ab08246ae2870aaa06e6008": new Set([rules.CRMS_DISBURSE_BUTTON]),
};

export default configs;
