export const QUERY_KEYS = {
  CUSTOMIZATION_KEY: "oze-customization",
  LOGO_KEY: "oze-logo",
  GET_LOAN: "oze-loan-info",
  GET_CREDIT_RISK: "credit-risk-info",
  GET_SCHEDULE: "oze-loan-schedule",
  GET_REPAYMENT: "oze-loan-repayment",
};

export const EMPTY_IMAGE = "https://via.placeholder.com/150";
export const THEME_KEY = "theme";
export const GLOBAL_STORE = "global_store";
export const LAST_VISITED_URL_KEY = "oze-last-visited-url-key";
export const DEFAULT_THEME = "4fe1fdabe0194933c66156c5c56a10fe";
export const THEME_TWO = "7a3e9b55d898410104f966e99f2cc16a";
export const midnightTime = "T00:00:00.000Z";
export const minuteToMidnight = "T23:59:59.999Z";
export const LC = {
  1: "4fe1fdabe0194933c66156c5c56a10fe",
  2: "7a3e9b55d898410104f966e99f2cc16a",
  3: "0a0d53a0569b8f50c07f3cc059cc661e",
  4: "6b7649fb5ab08246ae2870aaa06e6008",
};
